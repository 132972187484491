import { LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';

import { localStorageSync } from 'ngrx-store-localstorage';
import { AppComponent } from './app.component';

import localeFr from '@angular/common/locales/fr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './core/core.module';
import { appRouterReducer } from './core/store/reducers/router.reducers';

registerLocaleData(localeFr);
/**
 * Synchronize a part of helios request creation state
 * @param reducer
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function localStorageHeliosRequestCreationSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            {
                'helios-request-creation': [
                    'requestCreationPayload',
                    'worksites',
                    'heliosWorksitesCriteria',
                    'listCriteria',
                    'worksiteSupportAvailability',
                    'transportationNeedChange',
                ],
            },
        ],
        rehydrate: true,
        storage: localStorage, // Persist inside localStorage
    })(reducer);
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        /**
         * appRouterReducer allow us to have actual route datas (params, query params, url, data, ...) available in our store (router store)
         * sessionStorageUserSyncReducer synchronize a part of logged user infos to session storage
         */
        StoreModule.forRoot(appRouterReducer, {
            metaReducers: [localStorageHeliosRequestCreationSyncReducer],
            runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true },
        }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: true }),
        CoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    declarations: [AppComponent],
    providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {}
