/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { go } from '../store/actions/router.actions';
import { AppRouterState } from '../store/reducers/router.reducers';

/**
 * HTTP error interceptor / errors handler
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private routerStore: Store<AppRouterState>, private messageService: MessageService) {}

    /**
     * Intercept HTTP request for HTTP error handlings
     * @param request HttpRequest
     * @param next HttpHandler
     * @returns Observable<HttpEvent<any>>
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error) => this.handleResponseError(error, request, next)));
    }

    /**
     * for status unauthorized
     * @param request
     */
    private statusUnauthorized() {
        this.routerStore.dispatch(go({ path: ['/error-pages/unauthorized'] }));
    }

    /**
     * status forbidden
     */
    private statusForbidden() {
        this.routerStore.dispatch(go({ path: ['/error-pages/access-denied'] }));
    }

    /**
     * internal server error
     */
    private statusInternalServerError(error: HttpErrorResponse) {
        const trackingIdentifier = error.error?.trackingIdentifier;
        this.messageService.add({
            key: 'app-toaster',
            severity: 'error',
            summary: "Une erreur inattendue s'est produite",
            detail: `Veuillez enregistrer l' identifiant ci-dessous et le communiquer
          à l' administrateur de la plateforme pour obtenir de l' aide: ${trackingIdentifier}`,
        });
    }

    /**
     * unavailable
     */
    private statusServiceUnavailable() {
        this.routerStore.dispatch(go({ path: ['/error-pages/unavailable-service'] }));
    }

    /**
     * request timeout
     */
    private statusRequestTimeout() {
        this.messageService.add({
            key: 'app-toaster',
            severity: 'error',
            summary: 'Delai depassé',
            detail: "Le temps d'attente de la requête a été écoulé. Le serveur a mis trop de temps à repondre",
        });
    }

    /**
     * default status
     * @param error
     */
    private statusDefault(error: HttpErrorResponse) {
        this.messageService.add({
            key: 'app-toaster',
            severity: 'error',
            summary: 'Erreur',
            detail: error.error?.errorDetails?.value || error.error?.errorDetails || "Une erreur s'est produite",
            life: 5000,
        });
    }

    /**
     * response handler
     * @param error
     * @param request
     */
    private responseHander(error: HttpErrorResponse, request: HttpRequest<any>) {
        if (error.status === HttpStatusCode.Unauthorized) {
            this.statusUnauthorized();
        } else if (error.status === HttpStatusCode.Forbidden) {
            this.statusForbidden();
        } else if (error.status === HttpStatusCode.InternalServerError) {
            this.statusInternalServerError(error);
        } else if (error.status === HttpStatusCode.ServiceUnavailable) {
            this.statusServiceUnavailable();
        } else if ([HttpStatusCode.RequestTimeout, HttpStatusCode.GatewayTimeout].includes(error.status)) {
            this.statusRequestTimeout();
        } else {
            this.statusDefault(error);
        }
    }

    /**
     * Hanle HTTP error by redirecting to appropriate error page
     * depending on error status code, or just showing an error toaster for some cases
     * @param error HttpErrorResponse
     * @param request HttpRequest<any>
     * @param next HttpHandler
     * @returns Observable<HttpEvent<any>>
     */
    private handleResponseError(
        error: HttpErrorResponse,
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (error instanceof HttpErrorResponse) {
            this.responseHander(error, request);
        }
        return throwError(() => error);
    }
}
