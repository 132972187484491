/**
 * Default environment variable
 * apiBaseUrl: API routes base URL
 * jwtWhitelistedDomains: domain to which JWT token is allowed to be sent
 * blacklistedRoutes: route belonging to allowed domain but JWT token will not be sent to it (login route)
 */
export const environment = {
    production: false,
    apiBaseUrl: 'https://api-staging-authentication.agilog.net',
    jwtWhitelistedDomains: ['api-staging-authentication.agilog.net'],
    blacklistedRoutes: ['https://api-staging-authentication.agilog.net/login'],
};
